import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/Seo"

export default function Template({ data }) {
  const posts = data.posts
  const folder = data.folder.distinct[0]
  const pageTitle = folder
    ? `${folder.charAt(0).toUpperCase() + folder.substring(1)} index`
    : "Contents"

  const prefixStr = "the "

  const checkForPrefix = string => {
    return string.toLowerCase().startsWith(prefixStr.toLowerCase())
  }

  const removePrefix = string => {
    return string.substring(prefixStr.length)
  }

  const letterSet = new Set()

  const items = posts.edges.map(edge => {
    const title = edge.node.frontmatter.title
    let letter = ""
    if (checkForPrefix(title)) {
      letter = title.charAt(prefixStr.length).toUpperCase()
    } else {
      letter = title.charAt(0).toUpperCase()
    }

    letterSet.add(letter)

    return [letter, edge]
  })

  items.sort((a, b) => {
    let aa = a[1].node.frontmatter.title
    let bb = b[1].node.frontmatter.title

    if (checkForPrefix(aa)) {
      aa = removePrefix(aa)
    }
    if (checkForPrefix(bb)) {
      bb = removePrefix(bb)
    }
    return aa.localeCompare(bb)
  })

  const letters = [...letterSet].sort()

  return (
    <>
      <Seo title={pageTitle} />
      <h1>{pageTitle}</h1>
      <hr />
      <div style={{ margin: "1em 0" }}>
        {letters.map(letter => (
          <>
            <h2>{letter}</h2>
            <ul style={{ marginTop: "0" }}>
              {items.map(item =>
                item[0] === letter ? (
                  <li key={item[1].node.id}>
                    <Link to={item[1].node.frontmatter.path}>
                      {item[1].node.frontmatter.title}
                    </Link>
                  </li>
                ) : null
              )}
            </ul>
          </>
        ))}
      </div>
    </>
  )
}

export const contentQuery = graphql`
  query ($regexStr: String!, $category: String!) {
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { fileAbsolutePath: { regex: $regexStr } }
    ) {
      edges {
        node {
          frontmatter {
            path
            date
            title
          }
          id
        }
      }
    }
    folder: allMarkdownRemark(
      filter: { fields: { collection: { eq: $category } } }
    ) {
      distinct(field: fields___collection)
    }
  }
`
